<template>
  <div v-if="dados">
    <form @submit.prevent="validarPresenca" v-if="!confirmado">
      <div class="mb-4">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="mr-2">
            <h6 class="font-weight-bold mb-0">{{reuniao.nome_reuniao}}</h6>
            <p class="mb-0">Data: {{reuniao.data_reuniao}}</p>
          </div>
          <a href="javascript:" class="btn btn-sm ml-auto" @click="$emit('voltar')" title="Saiba mais">
            <i class="fa fa-info-circle" style="font-size: 18px"></i>
          </a>
          <a href="javascript:" class="btn btn-sm text-secondary ml-2" @click="share()" title="Compartilhar link">
            <i class="fa fa-share" style="font-size: 20px"></i>
          </a>
        </div>
        <hr>
        <div>
          <div class="alert d-flex align-items-center" :class="reuniao.expirada == 1 ? 'alert-warning' : 'alert-info'">
            <i class="fa fa-info-circle mr-1"></i>
            <div class="ml-1">
              <h6 class="font-weight-bold mb-0" v-if="reuniao.expirada == 1">Presença Indisponível</h6>
              <div class="small">Confirme sua presença até <b>6 horas</b> antes da reunião.</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="font-weight-bold small mb-0">Nome completo *</label>
            <input type="text" class="form-control" placeholder="Informe seu nome" v-model="dados.nome_membro" maxlength="128" required>
          </div>
          <div class="mb-2">
            <label class="mb-0 font-weight-bold small">Setor*</label>
            <select class="form-control" v-model="dados.id_setor" required>
              <option value="">Selecione</option>
              <option :value="s.id_setor" v-for="s in setores">Setor - {{ s.nome_setor }}</option>
            </select>
          </div>
          <div class="mb-2" v-for="p in reuniao.perguntas">
            <label class="font-weight-bold small mb-0">{{ p.nome_pergunta }} {{ p.required == 1 ? '*' : '' }}</label>
            <select class="form-control" v-model="p.resposta" :required="p.required == 1" v-if="p.opcoes">
              <option value="">{{ p.placeholder || 'Escolha uma opção'}}</option>
              <option v-for="opt in p.opcoes" :value="opt">{{opt}}</option>
            </select>
            <input type="text" class="form-control" :placeholder="p.placeholder" v-model="p.resposta" maxlength="128" :required="p.required == 1" v-else>
          </div>
        </div>
        <div class="mb-3">
          <label class="font-weight-bold small mb-0" for="observacao">Observações (opcional)</label>
          <textarea id="observacao" class="form-control" placeholder="Ex: Restrições alimentares, acessos especiais, etc." v-model="dados.observacao" rows="4" maxlength="256" style="resize: none"></textarea>
        </div>
        <div>
          <label>
            <input type="checkbox" :checked="salvar" @change="salvar=!salvar" class="mr-1">
            Salvar dados
          </label>
          <tooltip msg="Salvar nome e o setor da confirmação para reutilizar em outras reuniões." class="ml-2"/>
        </div>
      </div>
      <div class="fixed-bottom bg-white py-3 shadow-sm border-top">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <button class="btn btn-secondary btn-block py-2" :class="{'disabled': reuniao.expirada == 1}">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div v-else>
      <div class="text-center mt-5" style="max-width: 600px">
        <h4 class="font-weight-bold text-primary">Confirmado com sucesso!</h4>
        <p class="mb-3 small">
          Recebemos sua confirmação de presença
        </p>
        <div class="card shadow-sm mb-4">
          <div class="card-body">
            <p class="mb-0">{{reuniao.nome_reuniao}}</p>
            <p class="mb-0"><b>Data:</b> {{reuniao.data_reuniao}}</p>
          </div>
        </div>
        <div class="pb-4">
          <router-link to="/perfil/confirmacoes" class="btn btn-primary btn-block py-2 mb-3">PRESENÇAS CONFIRMADAS</router-link>
          <router-link to="/calendario" class="btn btn-outline-primary btn-block py-2">VOLTAR PARA HOME</router-link>
        </div>
      </div>
    </div>

    <modal-bottom :opened="confirmar">
      <div class="container">
        <h6 class='font-weight-bold my-3 text-center'>Podemos confirmar sua presença?</h6>
        <div><b>Nome:</b> {{dados.nome_membro}}</div>
        <div v-if="dados.observacao"><b>Observações:</b> {{dados.observacao}}</div>
        <hr>
        <div>
          <button class="btn btn-primary btn-block rounded-sm p-2 mb-3" @click="enviarConfirmacao" :disabled="btnLoad">
            <span v-show="!btnLoad"><b>Confirmar</b></span>
            <span v-show="btnLoad"><i class="fa fa-spinner fa-spin"></i> <b class="pl-2">Enviando sua confirmação</b></span>
          </button>
          <button type="button" class="btn btn-block rounded-sm p-3" @click="confirmar = false" v-show="!btnLoad">
            <span class="font-weight-bold text-primary">Cancelar</span>
          </button>
        </div>
      </div>
    </modal-bottom>
  </div>
</template>

<script>
  import ModalBottom from "../components/ModalBottom";
  import Tooltip from "@/components/Tooltip";

  export default {
    name: 'CalendarioPresenca',
    components: {Tooltip, ModalBottom},
    props: ['reuniao'],
    data() {
      return {
        load: true,
        user: {},
        dados: null,
        confirmado: false,
        confirmar: false,
        btnLoad: false,
        salvar: true,
        confirmacoes: []
      }
    },

    methods: {
      validarPresenca() {
        if (this.btnLoad) {
          return false;
        }

        if (this.reuniao.expirada == 1) {
          this.$swal('Presença Indisponível. Confirme sua presença até 6 horas antes da reunião.');
          return;
        }

        this.confirmar = true;
        return true;
      },

      enviarConfirmacao() {
        if (!this.validarPresenca()) {
          return;
        }

        if (this.reuniao.perguntas) {
          const json = {};
          this.reuniao.perguntas.forEach(p => {
            json[p.slug] = p.resposta;
            this.dados.json_data = json
          });
        }

        this.dados.id_reuniao = this.reuniao.id_reuniao;
        this.btnLoad = true;

        this.$http.post('app/presencas/' + this.regionalId, this.dados)
          .then(response => {
            const data = response.data;

            if (data && data.success) {
              this.confirmar = false;
              this.confirmado = true;

              // salvar a reuniao local
              this.dados.nome_reuniao = this.reuniao.nome_reuniao;
              this.dados.data_reuniao = this.reuniao.data_reuniao;
              this.dados.timestamp = this.convertTime(this.reuniao.data_reuniao);
              this.dados.id_presenca = data.id_presenca;
              this.confirmacoes.push(this.dados);
              localStorage.setItem('confirmacoes', JSON.stringify(this.confirmacoes));

              // salvar para usar depois
              if (this.salvar) {
                this.salvarUser();
              }

            } else {
              this.$swal(data && data.msg ? data.msg : 'Erro temporário');
            }

            this.btnLoad = false;

          }, res => {
            console.log(res);
            this.btnLoad = false;
          });
      },

      salvarUser() {
        this.user.nome_membro = this.dados.nome_membro || '';
        this.user.id_setor = this.dados.id_setor || '';
        this.user.observacao = this.dados.observacao || '';
        this.$store.commit('setDataUser', this.user);
      },

      convertTime(dataString) {
        const [data, hora] = dataString.split(' - ');
        const [dia, mes, ano] = data.split('/');
        const [horas, minutos] = hora.split(':');
        const date = new Date(ano, mes - 1, dia, horas, minutos);
        return date.getTime();
      },

      share() {
        try {
          navigator.share({
            text: `${this.reuniao.nome_reuniao} - ${this.reuniao.data_reuniao}. Acesse o link para confirmar sua presença.`,
            url: `https://calendarioccb.com.br/presenca/${this.reuniao.id_reuniao}`,
          });

        } catch (err) {
          this.$swal('Dispositivo incompatível com o compartilhamento de lista.');
        }
      }
    },

    computed: {
      regional() {
        return this.$store.state.dados.regional
      },

      setores() {
        return this.$store.state.dados.setores
      }
    },

    mounted() {
      this.user = this.$store.state.user;
      this.confirmacoes = this.$helpers.parseJSONObject(localStorage.getItem('confirmacoes')) || [];

      this.dados = {
        id_reuniao: '',
        nome_membro: '',
        id_setor: '',
        observacao: '',
        json_data: null
      }

      if (this.user.token) {
        this.dados.nome_membro = this.user.nome_membro || '';
        this.dados.id_setor = this.user.id_setor || '';
        this.dados.observacao = this.user.observacao || '';
      }
    }
  }
</script>

<style scoped>
</style>
