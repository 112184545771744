<template>
  <div>
    <botton-bar/>
    <div class="content">
      <div class="container">
        <div>
          <div class="mb-3 text-center">
            <h4 class="mb-0">CRS</h4>
            <p class="mb-0">Calendário de Reuniões e Serviços</p>
          </div>
          <hr>
          <div class="border-bottom mb-3" v-if="regional.endereco">
            <h6 class="font-weight-bold mb-1 text-primary">Regional Administrativa:</h6>
            <p class="small mb-3">{{regional.nome_regional}} - {{regional.sigla_estado}}</p>
          </div>
          <div class="mb-3" v-if="regional.endereco">
            <h6 class="font-weight-bold mb-1 text-primary">Endereço:</h6>
            <p class="small mb-3">{{regional.endereco}}, {{regional.numero}}, {{regional.bairro}}, {{regional.nome_cidade}} - {{regional.sigla_estado}}</p>
          </div>
          <div class="border-bottom mb-3" v-if="regional.nome_presidente">
            <h6 class="font-weight-bold mb-1 text-primary">Presidente</h6>
            <p class="small mb-3">{{regional.nome_presidente}}</p>
          </div>
          <div class="border-bottom mb-3" v-if="regional.email">
            <h6 class="font-weight-bold mb-1 text-primary">E-mail</h6>
            <p class="small mb-3">{{regional.email}}</p>
          </div>
          <div class="border-bottom mb-3" v-if="regional.phone">
            <h6 class="font-weight-bold mb-1 text-primary">Telefone</h6>
            <p class="small mb-3">{{regional.phone | phone}}</p>
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-body text-center">
            <h6 class="text-primary font-weight-bold mb-2">CRS 2024</h6>
            <div class="small mb-0">Desenvolvido e mantido</div>
            <div class="small mb-3">Departamento Informática Guaxupé - MG</div>
            <a class="btn btn-outline-primary" href="https://wa.me/5535998435516" target="_blank">Entrar em contato</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BottonBar from "@/components/BottonBar";
  export default {
    name: 'Perfil',
    components: {BottonBar},
    computed: {
      regional() {
        return this.$store.state.dados.regional
      }
    }
  }
</script>
<style scoped>
</style>
