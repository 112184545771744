<template>
  <div id="app">
    <loading v-if="load" :fullscrean="true" text="Por favor aguarde"/>
    <div v-else-if="dados.regional">
      <router-view/>
    </div>
    <status-regional v-else/>
  </div>
</template>

<script>
  import Loading from "./components/Loading";
  import StatusRegional from "@/components/StatusRegional";

  export default {
    components: {StatusRegional, Loading},
    data() {
      return {
        load: true,
        dados: {},
        user: {}
      }
    },

    methods: {
      getDados() {
        this.$http.get('app/dados/' + this.regionalId)
          .then(response => {
            this.dados = response.data;
            this.$store.commit('setDadosApp', this.dados);
            this.load = false;
            this.getUser();

          }, res => {
            console.log(res);
            this.load = false;
          });
      },

      getUser() {
        const user = this.$helpers.parseJSONObject(localStorage.getItem("userData"));
        this.user = user || { nome_membro: '', token: this.$helpers.ID(), id_setor: '' };
        this.$store.commit('setDataUser', this.user);
        let confirmacoes = this.$helpers.parseJSONObject(localStorage.getItem('confirmacoes')) || null;

        if (confirmacoes) {
          const now = new Date().getTime();
          localStorage.setItem('confirmacoes', JSON.stringify(confirmacoes.filter(c => c.timestamp > now)));
        }
      }
    },

    mounted() {
      this.getDados();
    }
  }
</script>
