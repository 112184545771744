import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    android: false,
    user: { nome_membro: '', token: null, id_setor: '' },
    dados: { regional: {}, setores: [] },
    notificacoes: [],
    calendario: { datas: [], ano: '', id_setor: '' },
    listas: [],
    meses: [
      {"mes": "Janeiro", "valor": 1},
      {"mes": "Fevereiro", "valor": 2},
      {"mes": "Março", "valor": 3},
      {"mes": "Abril", "valor": 4},
      {"mes": "Maio", "valor": 5},
      {"mes": "Junho", "valor": 6},
      {"mes": "Julho", "valor": 7},
      {"mes": "Agosto", "valor": 8},
      {"mes": "Setembro", "valor": 9},
      {"mes": "Outubro", "valor": 10},
      {"mes": "Novembro", "valor": 11},
      {"mes": "Dezembro", "valor": 12}
    ]
  },

  mutations: {
    setDadosApp: (state, data) => {
      state.dados = data;
    },

    setDataUser: (state, data) => {
      localStorage.setItem('token', data.token);
      localStorage.setItem('userData', JSON.stringify(data));
      state.user = data;
    },

    logout(state) {
      localStorage.clear();
      state.user = {};
    }
  },

  actions: {
  },

  modules: {
  }
})
