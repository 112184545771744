<template>
  <div>
    <botton-bar/>
    <div class="content">
      <div class="container">
        <div class="row justify-content-between align-items-end">
          <div class="col-md-6 mb-3 mb-md-0 text-center text-md-left">
            <h4 class="mb-0">Notificações</h4>
          </div>
        </div>
        <hr>
        <div>
          <div class="mt-3 alert alert-secondary text-center py-4" v-if="!notificacoes.length">
            Nenhuma notificação disponível
          </div>
          <div class="mt-3" v-for="n in notificacoes">
            <lista :nome="n.nome_reuniao" :url="`/calendario/${n.id_reuniao}`">
              <p class="m-0 text-dark">
                {{ n.data_reuniao }}
              </p>
            </lista>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CardInfo from "../components/CardInfo";
  import BottonBar from "@/components/BottonBar";
  import Lista from "@/components/Lista";

  export default {
    name: 'Notificacoes',
    components: {Lista, BottonBar, CardInfo},

    data() {
      return {
        token: localStorage.getItem('token'),
        pesquisa: { id_setor: '', intervalo: '1' },
        notificacoes: []
      }
    },

    methods: {
      buscarNotificacoes() {
        const notificacoes = this.$helpers.parseJSONObject(localStorage.getItem('confirmacoes')) || [];

        if (notificacoes) {
          this.notificacoes = notificacoes.sort((a, b) => a.timestamp - b.timestamp);
        }
      },
    },

    mounted() {
      this.buscarNotificacoes();
    },

    computed: {
      setores() {
        return this.$store.state.dados.setores
      }
    },

    filters: {
      date(d) {
        const date = d ? d.split('-') : null;
        return date && date.length === 3 ? `${date[2]}/${date[1]}/${date[0]}` : '';
      }
    }
  }
</script>
<style scoped>
</style>