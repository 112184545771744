<template>
  <div id="bottom-bar">
    <div id="header" class="bg-white shadow-sm" style="z-index: 9;">
      <div class="container-fluid py-2 px-3 px-md-4">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-md-6">
            <router-link to="/calendario" class="text-dark text-decoration-none" aria-label="Acessar home da aplicação">
              <h2 class="font-weight-bold h6 py-3 m-0">Calendário CCB</h2>
            </router-link>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <router-link to="/listas" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Quem somos">
              <span class="icone-text">Listas</span>
            </router-link>
            <router-link to="/notificacoes" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Quem somos">
              <span class="icone-text">Notificações</span>
            </router-link>
            <router-link to="/regional" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Quem somos">
              <span class="icone-text">Regional</span>
            </router-link>
            <router-link to="/perfil" class="icone-desktop not-decoration" active-class="active" role="listitem" aria-label="Meu Perfil" v-if="token">
              <span class="icone-text">Perfil</span>
            </router-link>
            <router-link to="/perfil/login" class="btn btn-primary text-nowrap ml-4 d-none" active-class="active" role="listitem" aria-label="Meu Perfil" v-else>
              Entrar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center fixed-bottom d-md-none" style="z-index: 9">
      <div class="bottom-bar shadow-top position-relative" style="border-radius: 24px 24px 0 0" role="navigation">
        <div class="container">
          <div class="row align-items-center" role="list">
            <router-link to="/calendario" active-class="active" class="col icone-bar not-decoration" role="listitem" aria-label="Home">
              <div aria-hidden="true">
                <img src="../assets/icones/calendar-lines.svg" alt="Calendário">
                <div class="text-bottom">Calendário</div>
              </div>
            </router-link>
            <router-link to="/listas" active-class="active" class="col icone-bar not-decoration" role="listitem" aria-label="Home">
              <div aria-hidden="true">
                <img src="../assets/icones/rectangle-list.svg" alt="Listas">
                <div class="text-bottom">Listas</div>
              </div>
            </router-link>
            <router-link to="/notificacoes" active-class="active" class="col icone-bar not-decoration" style="position: relative" role="listitem" aria-label="Informativos">
              <div aria-hidden="true">
                <img src="../assets/icones/bell.svg" alt="Notificações">
                <span class="n-notificacao" v-show="qtdNotificacao > 0">{{qtdNotificacao}}</span>
                <div class="text-bottom">Notificações</div>
              </div>
            </router-link>
            <router-link to="/regional" active-class="active" class="col icone-bar not-decoration" role="listitem" aria-label="Regional">
              <div aria-hidden="true">
                <img src="../assets/icones/house-blank.svg" alt="Home">
                <div class="text-bottom">Regional</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
export default {
  name: 'BottonBar',
  components: {Icon},
  data() {
    return {
      token: localStorage.getItem('token'),
      qtdInfo: 1
    }
  },
  computed: {
    regional() {
      return this.$store.state.dados
    },

    qtdNotificacao() {
      return this.$store.state.notificacoes.length
    }
  },
}
</script>
<style scoped>
.bottom-bar {
  background-color: var(--primary);
}

.icone-bar {
  padding: 14px 0;
  color: #ffffff;
}

.icone-bar.active:first-child {
  border-radius: 24px 0 0;
}

.icone-bar.active:last-child {
  border-radius: 0 24px 0 0;
}

.icone-bar img {
  width: 18px;
}

.text-bottom {
  font-size: 11px;
  margin-top: 6px;
}

.icone-desktop {
  padding: 12px 16px;
  color: var(--dark);
  margin-left: 12px;
  text-align: center;
  display: none;
  align-items: baseline;
  position: relative;
}

.icone-desktop .icone-text {
  font-size: 0.9em;
  white-space: nowrap;
  margin-left: 6px;
}

.icone-desktop.active {
  color: var(--primary);
  font-weight: bold;
}

.icone-desktop.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
}

.n-notificacao {
  position: absolute;
  left: 50%;
  top: 6px;
  transform: translateX(-50%);
  margin-left: 12px;
  background-color: var(--secondary)!important;;
  color: #fff!important;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.n-desktop {
  left: 26px;
  top: -6px;
  transform: none;
  margin-left: 0;
}

.icone-bar.active {
  background-color: rgba(0, 0, 0, 0.2);
}

.icone-bar.active::before {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  top: 10px;
  right: 33%;
  background-color: #ffffff;
}

.ios_platform .icone-bar {
  padding-bottom: 34px;
}

@media (min-width: 768px) {
  .icone-desktop {
    display: flex;
  }
}
</style>
