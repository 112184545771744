<template>
  <div class="container text-center">
    <loading v-if="load" fullscrean="true" text="Redirecionando para o App"/>
    <div class="mx-auto" style="padding-top: 20vh; max-width: 500px" v-else>
      <h4 class="mb-1">CRS - Calendário Digital</h4>
      <p class="mb-0">Calendário de Reuniões e Serviços da Congregação Cristã no Brasil</p>
      <div style="margin-top: 42px">
        <a :href="android" v-if="android" target="_blank" class="btn btn-links">
          <i class="fa fa-play"></i>
          Baixar App Android
        </a>
        <a :href="ios" v-if="ios" target="_blank" class="btn btn-links">
          <i class="fa fa-apple"></i>
          Baixar App iOS
        </a>
      </div>
      <div class="mb-3" style="margin-top: 42px">
        <div>
          <span class="d-block">Desenvolvido por:</span>
          <b>Departamento Informática - Guaxupé</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
  name: 'Share',
  components: {Loading},
  data() {
    return {
      load: true,
      android: 'https://play.google.com/store/apps/details?id=br.com.calendarioccb.twa',
      ios: 'https://apps.apple.com/br/app/calendário-ccb/id6475646146'
    }
  },

  mounted() {
    const ua = navigator.userAgent

    if (/android/i.test(ua)) {
      window.location.href = this.android;

    } else if (/iPad|iPhone|iPod/.test(ua)) {
      window.location.href = this.ios;

    } else {
      this.load = false;
    }
  },

  computed: {
    regional() {
      return this.$store.state.dados.regional;
    }
  }
}
</script>
<style scoped>
.btn-links {
  text-align: center;
  display: block;
  background-color: var(--primary);
  color: #ffffff;
  border-radius: 14px;
  position: relative;
  padding: 10px 0;
  text-transform: none;
  width: 98%;
  margin: 0 auto 12px;
  transition: 0.3s;
}

.btn-links i {
  position: absolute;
  left: 14px;
  top: 12px;
  font-size: 20px;
}

.btn-links:hover {
  color: #ffffff;
  width: 100%;
}

.img-regional {
  border-radius: 12px;
  width: 140px;
  height: 140px;
  display: block;
  margin: 0 auto 20px;
  background-color: var(--primary);
  overflow: hidden;
  padding: 16px;
}

.img-regional img {
  width: 100%;
  object-fit: contain;
}
</style>