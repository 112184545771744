<template>
  <div>
    <top-bar title="Presenças confirmadas" url="/perfil"/>
    <loading v-if="load" fullscrean="true"/>
    <div class="container mb-5" v-else style="max-width: 600px">
      <div class="text-center" style="padding-top: 43px" v-if="!confirmacoes.length">
        <i class="fa fa-check fa-3x text-primary"></i>
        <p class="mt-3">Nenhuma confirmação de presença registrada</p>
      </div>
      <div class="card shadow-sm mb-2" v-for="c in confirmacoes">
        <div class="card-body">
          <h5 class="text-primary mb-1">{{ c.nome_reuniao }}</h5>
          <div>
            <p class="small mb-0">Data da reunião: {{c.data_reuniao}}</p>
            <p class="small mb-0">Observações: {{c.observacao}}</p>
          </div>
          <hr>
          <div class="row no-gutters">
            <div class="col-6 pr-1">
              <button class="btn btn-outline-dark btn-sm btn-block" @click="cancelarPresenca(c.id_presenca)"><i class="fa fa-times"></i> Cancelar</button>
            </div>
            <div class="col-6 pl-1">
              <router-link :to="`/calendario/${c.id_reuniao}`" class="btn btn-primary btn-sm btn-block">Ver Reunião</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import OneSignal from "../components/OneSignal";
  import Loading from "@/components/Loading";

  export default {
    name: 'MinhasPresencas',
    components: {Loading, OneSignal, TopBar},
    data() {
      return {
        load: false,
        confirmacoes: []
      }
    },

    methods: {
      getDados() {
        this.confirmacoes = this.$helpers.parseJSONObject(localStorage.getItem('confirmacoes')) || [];
      },

      cancelarPresenca(id_presenca) {
        if (!id_presenca) {
          this.$swal("Não foi possível remover essa presença, contate um administrador.");
          return;
        }

        this.$swal({
          text: "Deseja realmente cancelar sua presença nesta reunião?",
          buttons: ["Voltar", "Sim!"],
          dangerMode: true
        }).then(res => {
          if (res) {
            this.load = true;

            this.$http.post('app/presencas/' + this.regionalId, { id_presenca, cancelar: true })
              .then(res => {
                this.load = false;

                if (res.data.success) {
                  this.confirmacoes = this.confirmacoes.filter(c => c.id_presenca !== id_presenca);
                  localStorage.setItem('confirmacoes', JSON.stringify(this.confirmacoes));
                  this.$swal('Presença cancelada com sucesso!');
                  this.getDados();
                }

              }, res => {
                let msg = res.data && res.data.msg ? res.data.msg : 'Erro temporário';
                this.$swal(msg);
              });
          }
        });
      }
    },

    mounted() {
      this.getDados();
    },

    computed: {
      user() {
        return this.$store.state.user;
      }
    }
  }

</script>
<style scoped>
</style>
