<template>
  <div class="modal-preview" v-show="opened">
    <div class="modal-preview-content shadow-sm">
      <top-bar title="Lista" url="/listas" class="d-print-none"/>
      <loading :fullscrean="true" v-if="load" text="Buscando a lista"/>
      <div class="container text-center" v-else-if="!lista">
        <div class="alert alert-warning">Lista não encontrada</div>
      </div>
      <div class="container preview" v-else>
        <div class="text-center mb-3 p-1 text-uppercase">
          <h5 class="mb-1 font-weight-bold">CONGREGAÇÃO CRISTÃ NO BRASIL</h5>
          <h6 class="mb-1 font-weight-bold" v-if="lista.mes_lista">REGIONAL GUAXUPÉ - {{ meses.find(m => m.valor == lista.mes_lista).mes }} de {{ lista.ano_lista }}</h6>
          <h6 class="mb-0 font-weight-bold">Lista de Batismos e Diversos</h6>
        </div>
        <div class="alert alert-info text-center" v-if="lista.status === 2">
          Esta é uma <b>Pré Lista</b>, as informações podem ser alteradas na lista oficial.
        </div>
        <div class="border mb-2" v-for="cat in lista.categorias">
          <div class="title">{{ cat.nome_categoria }}</div>
          <table class="table table-striped table-sm mb-0" v-if="cat.layout == 1">
            <thead>
            <tr>
              <th class="text-center" style="width: 50px">Data</th>
              <th class="text-center" style="width: 50px">Hora</th>
              <th style="width: 50%">Localidade</th>
              <th>Ancião</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="r in cat.reunioes">
              <td class="text-center">{{ r.data_reuniao }}</td>
              <td class="text-center">{{ r.hora_reuniao }}</td>
              <td>{{ r.nome_cidade }} - {{ r.nome_localidade }}</td>
              <td>{{ r.presidencia || '-' }}</td>
            </tr>
            </tbody>
          </table>
          <table class="table table-striped table-sm mb-0" v-else>
            <thead>
            <tr>
              <th class="text-center" style="width: 50px">Data</th>
              <th class="text-center" style="width: 50px">Hora</th>
              <th>Reunião</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="r in cat.reunioes">
              <td class="text-center">{{ r.data_reuniao }}</td>
              <td class="text-center">{{ r.hora_reuniao }}</td>
              <td>
                {{ r.nome_reuniao }}
                <b v-if="r.nome_cidade"><br>{{ r.nome_cidade }} - {{ r.nome_localidade }}</b>
                <span v-if="r.presidencia"><br>{{ r.presidencia }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="border mb-2" v-for="a in lista.avisos">
          <div class="title">{{a.titulo}}</div>
          <div class="p-2 message text-center" v-html="a.mensagem"></div>
        </div>
      </div>
      <div class="fixed-bottom bg-light border-top py-3 shadow d-print-none">
        <div class="container text-center">
          <button class="btn btn-outline-primary mr-2" @click="abrirLista()" :disabled="!lista">
            <i class="fa fa-refresh mr-1"></i> Sincronizar
          </button>
          <button class="btn btn-outline-primary" @click="compartilhar()" :disabled="!lista">
            <i class="fa fa-share mr-1"></i> Compartilhar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import Loading from "@/components/Loading";

export default {
  name: "ListaPreview",
  components: {Loading, TopBar},
  props: ['preview', 'meses'],
  data() {
    return {
      load: false,
      id_lista: null,
      lista: null,
      opened: false
    }
  },

  methods: {
    closeModal() {
      document.body.classList.remove("modal-open");
      this.opened = false;
      this.$emit('closeModal');
    },

    abrirLista() {
      this.load = true;
      this.lista = null;

      this.$http.get(`listas/${this.id_lista}/preview?lista=true`)
        .then(response => {
          this.load = false;

          if (response.data && response.data.id_lista) {
            this.lista = response.data;
          }

        }, res => {
          this.load = false;
        });
    },

    compartilhar() {
      try {
        navigator.share({
          title: `Lista de Batismo`,
          text: `REGIONAL GUAXUPÉ - ${this.meses.find(m => m.valor == this.lista.mes_lista).mes} de ${this.lista.ano_lista}`,
          url: window.location.href,
        });

      } catch (err) {
        this.$swal('Dispositivo incompatível com o compartilhamento de lista.');
      }
    }
  },

  created() {
    this.$parent.$on('openModal', (id_lista) => {
      document.body.classList.add("modal-open");
      this.opened = true;
      this.id_lista = id_lista;
      this.abrirLista();
    });

    this.$parent.$on('closeModal', () => {
      this.closeModal();
    });
  },
}
</script>

<style>
.modal-preview {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1040; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-preview-content {
  background-color: #fefefe;
  position: relative;
  overflow: auto;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.preview {
  max-width: 600px;
  margin: auto;
  text-transform: uppercase;
  padding-bottom: 92px;
  color: #000000;
}

.preview, .preview .table {
  color: #000000;
  -webkit-print-color-adjust: exact;
}

.preview:nth-child(1) {
  margin-right: 20px;
}

.preview .border {
  border-color: #1b1e21!important;
}

.preview .title, .preview .message {
  padding: 2px 0;
  border-color: #1b1e21;
}

.preview .message p {
  font-size: 0.9rem;
  margin-bottom: 4px;
  color: #000000;
}

.preview .message p:last-child {
  margin-bottom: 0;
}

.preview .title {
  font-size: 1rem;
  text-align: center;
  border-bottom: 1px solid #1b1e21;
  font-weight: bold;
  background-color: #C9C9C9;
}

.preview td {
  font-size: 0.8rem;
  border-color: #1b1e21!important;
  padding: 0.1rem!important;
}

.preview th {
  font-size: 0.8rem;
  border: none;
  padding: 0.1rem!important;
}

.preview tr:nth-child(odd) td {
  background-color: #E9E9E9 !important;
  -webkit-print-color-adjust: exact;
}

@media print {
  .preview {
    max-width: none;
    padding: 12px 0;
  }
}
</style>
