<template>
  <div>
    <top-bar title="Termos"/>
    <div class="container">
      <h1 class="h4 text-center">Termos e condições de uso</h1>
      <p id="h.3cqnqgg6xbmk"><span class="c5 c9">Por favor, leia com atenção. Ao se cadastrar no CRS você está de acordo com estes termos e condições.</span></p>
      <h5 class="my-4 text-primary" id="h.bjr3b8pvtdni"><span class="c1">1. SERVIÇOS OFERECIDOS</span></h5>
      <p class="c4"><span class="c2 c3">1.1. </span><span class="c5 c2">Este TERMO se aplica para regular o uso do serviço oferecido pelo CRS aos USUÁRIOS, qual seja, ver as reuniões e listas ou solicitações confirmação de presença em reuniões. O serviço do CRS consiste, portanto, em auxiliar os USUÁRIOS e as REGIONAIS.</span></p>
      <p class="c4"><span class="c2 c3">1.2</span> <span class="c5 c2">Desde logo fica esclarecido ao USUÁRIO - o qual se declara ciente - que o serviço oferecido pelo CRS se relaciona apenas à intermediação, sendo os dados aqui informados de responsabilidade integral da REGIONAL, a quem deverão ser direcionados quaisquer reclamações.</span></p>
      <h5 class="mb-4 text-primary" id="h.52nmgmwn8826"><span class="c1">2. CADASTRO</span></h5>
      <p class="c4"><span class="c2 c3">2.1</span> <span class="c2">O USUÁRIO, para utilizar os serviços acima descritos, poderá salvar seus dados localmente em seu dispositivo, estes dados não serão encaminhados para o servidor do CRS.</span></p>
      <h5 class="my-4 text-primary" id="h.rciu29x82k4z"><span class="c1">3. OBRIGAÇÕES DO USUÁRIO</span></h5>
      <p class="c4"><span class="c2 c3">3.1. É obrigação do USUÁRIO usar com responsabilidade os dados fornecidos.</span></p>
      <h5 class="my-4 text-primary" id="h.paw65jkxmici"><span class="c1">4. OBRIGAÇÕES DO CRS</span></h5>
      <p class="c4"><span class="c2 c3">4.1</span> <span class="c5 c2">Disponibilizar no site um espaço virtual que permita ao USUÁRIO devidamente cadastrado receber alertas e confirmar presença em reuniões e serviços.</span></p>
      <p class="c4"><span class="c2 c3">4.2. </span><span class="c5 c2">Proteger, a confidencialidade das informações relativas aos USUÁRIOS. Contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.</span></p>
      <h5 class="my-4 text-primary" id="h.qzlk61sfysdv"><span class="c1">5. MODIFICAÇÕES DESTE TERMO</span></h5>
      <p class="c4"><span class="c2 c3">5.1</span> <span class="c5 c2">O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços disponibilizados, e as novas condições entrarão em vigência assim que veiculada no App.</span></p>
      <h5 class="my-4 text-primary" id="h.51b3i79iu5se"><span class="c1">6. ACEITAÇÃO DO TERMO DE USO</span></h5>
      <p class="c4"><span class="c2 c3">6</span><span class="c2 c3">.1</span> <span class="c5 c2">O USUÁRIO declara ter lido, entendido e que aceita todas as regras, condições e OBRIGAÇÕES estabelecidas no presente TERMO.</span></p>
      <h5 class="my-4 text-primary" id="h.gfgo8d2xgl8j"><span class="c1">7. FORO DE ELEIçãO</span></h5>
      <p class="c4"><span class="c2 c3">7</span><span class="c2 c3">.1</span> <span class="c5 c2">As partes elegem como competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do presente TERMO o foro da Comarca de Guaxupé - MG.</span></p>
      <p class="my-5"><span class="font-weight-bold">Última atualização:</span> 03 de agosto de 2024</p>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar"
  export default {
    name: "Terms",
    components: {TopBar},
    mounted() {
      window.scrollTo(0,0)
    }
  }
</script>

<style scoped>

</style>
