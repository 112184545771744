<template>
  <div>
    <botton-bar/>
    <div class="content">
      <div class="container">
        <loading v-if="load" :fullscrean="true" text="Buscando calendário"/>
        <div style="max-width: 600px" v-else>
          <div class="card card-filtro mb-3">
            <a href="javascript:" class="bg-primary text-white p-3 d-flex justify-content-between align-items-center text-decoration-none"
               @click="showFiltros = !showFiltros">
              <h6 class="text-center mb-0">Filtros</h6>
              <i class="fa" :class="showFiltros ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </a>
            <div style="padding: 10px 16px 16px" v-show="showFiltros">
              <div class="form-group mb-2">
                <label class="mb-0">Setor:</label>
                <select class="form-control" v-model="pesquisa.id_setor" @change="getReunioes()">
                  <option value="">Todos os setores</option>
                  <option :value="s.id_setor" v-for="s in setores">Setor - {{ s.nome_setor }}</option>
                </select>
              </div>
              <div class="form-group mb-0">
                <label class="mb-0">Calendário:</label>
                <select class="form-control" v-model="pesquisa.ano" @change="getReunioes()">
                  <option :value="2024">2024</option>
                  <option :value="2023">2023</option>
                </select>
              </div>
            </div>
          </div>
          <div v-show="!showFiltros">
            <div class="d-flex">
              <input type="search" class="form-control mr-2" placeholder="Pesquisar reunião" v-model="termo" @search="pesquisar()">
              <button class="btn btn-secondary text-nowrap px-3" @click="pesquisar()"><i class="fa fa-search"></i><span class="ml-2 d-none d-md-inline">Pesquisar</span></button>
            </div>
          </div>
          <div class="pt-3" id="listaReunioes" v-show="!isSearching">
            <v-date-picker locale="pt-BR" is-expanded :first-day-of-week="1" :attributes='attributes'
               ref="calendar" v-model="selectedDate" :max-date="maxDate" :min-date="minDate" @dayclick="toggleDia"/>
          </div>
          <div class="mt-3" v-if="selected" style="min-height: 40vh">
            <div class="alert alert-warning d-flex justify-content-between" v-if="isSearching">
              Pesquisa ativada
              <a href="javascript:" class="text-dark small" @click="pesquisar(true)"><u>Limpar pesquisa</u></a>
            </div>
            <div class="mt-3 alert alert-secondary text-center py-4" v-if="!selected.length">
              {{ isSearching ? 'Nenhuma reuniões encontrada' : 'Não há reuniões neste dia' }}
            </div>
            <div class="mt-2" v-for="r in selected">
              <lista :nome="r.nome_reuniao" @go="openReuniao" :id="r.id_reuniao" icone="true">
                <div>
                  <p class="m-0 text-dark" v-if="isSearching">
                    {{ r.data_reuniao | date }} - {{ r.hora_reuniao }} | {{`${r.nome_cidade} (${r.nome_localidade})`}}
                  </p>
                  <p class="m-0 text-dark" v-else>{{`Horário: ${r.hora_reuniao}`}} - {{`${r.nome_cidade} (${r.nome_localidade})`}}</p>
                </div>
              </lista>
            </div>
          </div>
        </div>

        <modal-reuniao ref="modal" title="Reunião" />
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import Loading from "../components/Loading";
  import ModalReuniao from "@/components/ModalReuniao";
  import BottonBar from "@/components/BottonBar";

  export default {
    name: 'Calendario',
    components: {BottonBar, ModalReuniao, Loading, Lista, TopBar},

    data() {
      const date = new Date();

      return {
        date,
        load: true,
        pesquisa: { id_setor: '', ano: '' },
        minDate: null,
        maxDate: null,
        selected: null,
        selectedDate: null,
        attributes: null,

        isSearching: false,
        showFiltros: false,
        termo: ''
      }
    },

    methods: {
      getReunioes() {
        this.load = true;
        this.showFiltros = false;
        this.selected = null;
        this.isSearching = false;
        this.termo = '';

        if (this.pesquisa.id_setor) {
          localStorage.setItem('setor', this.pesquisa.id_setor);

        } else {
          localStorage.removeItem('setor');
        }

        this.$http.get('app/reunioes/' + this.regionalId, { params: this.pesquisa })
          .then(response => {
            const datas = (response.data || []).map(r => { return {
              ...r,
              _termo: (`${r.nome_reuniao} ${r.nome_cidade} ${r.nome_localidade}`).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
            }});

            this.$store.state.calendario = { datas, ano: this.pesquisa.ano, id_setor: this.pesquisa.id_setor };
            this.preencherCalendario(datas);

          }, res => {
            this.load = false;
            console.log(res);
          });
      },

      toggleDia(date) {
        this.selected = this.selectedDate ? date.attributes.map(d => d.customData) : null;

        if (this.$helpers.isMobile()) {
          this.$nextTick(() => {
            const el = document.getElementById('listaReunioes');
            if (el) { el.scrollIntoView() }
          });
        }
      },

      preencherCalendario(datas) {
        this.minDate = new Date(this.pesquisa.ano, 0, 1);
        this.maxDate = new Date(this.pesquisa.ano, 11, 31);
        const attr = [];

        datas.forEach(d => {
          const date = d.data_reuniao.split('-');
          attr.push({
            key: d.id_reuniao,
            dot: true,
            highlight: false,
            dates: new Date(date[0], date[1] - 1, date[2]),
            customData: d
          });
        });

        this.attributes = attr;
        this.$nextTick(() => {
          this.getToday();
          this.load = false;
        });
      },

      openReuniao(id) {
        this.$router.push('/calendario/' + id);
      },

      getToday() {
        let today;

        if (this.date.getFullYear() === this.pesquisa.ano) {
          this.selectedDate = this.date;
          let year = this.date.getFullYear();
          let month = ("0" + (this.date.getMonth() + 1)).slice(-2);
          let date = ("0" + this.date.getDate()).slice(-2);
          today = `${year}-${month}-${date}`;

        } else {
          this.selectedDate = new Date(this.pesquisa.ano, 0, 1);
          today = `${this.pesquisa.ano}-01-01`;
        }

        this.selected = (this.attributes.filter(at => at.customData.data_reuniao === today) || []).map(d => d.customData);
      },

      pesquisar(clear) {
        const termo = clear ? '' : this.termo.trim().toLowerCase();

        if (termo) {
          const today = new Date().toJSON().slice(0,10);
          const calendario = (this.$store.state.calendario.datas || []).filter(r => r.data_reuniao >= today);
          this.selected = calendario.filter(r => r._termo.includes(termo));
          this.isSearching = true;

        } else {
          this.termo = '';
          this.selected = null;
          this.isSearching = false;
          this.getToday();
        }
      }
    },

    mounted() {
      const calendario = this.$store.state.calendario;
      const anoAtual = this.date.getFullYear();
      this.pesquisa.id_setor = localStorage.getItem('setor') || '';

      if (calendario.ano === anoAtual && calendario.id_setor == this.pesquisa.id_setor) {
        this.pesquisa.ano = calendario.ano;
        this.preencherCalendario(calendario.datas);

      } else {
        this.pesquisa.ano = anoAtual;
        this.getReunioes();
      }

      const id = this.$route.params.id;

      if (id && !isNaN(parseInt(id))) {
        this.$emit('openModal', id);
      }
    },

    computed: {
      setores() {
        return this.$store.state.dados.setores
      }
    },

    filters: {
      date(d) {
        const date = d ? d.split('-') : null;
        return date && date.length === 3 ? `${date[2]}/${date[1]}/${date[0]}` : '';
      }
    },

    watch: {
      $route(to, from) {
        if (to.name === 'ReuniaoHome' && to.params.id) {
          this.$emit('openModal', to.params.id);

        } else {
          this.$emit('closeModal');
        }
      }
    },
  }
</script>

<style>
  .card-filtro {
    border-radius: 8px;
    overflow: hidden;
  }

  .vc-dot:nth-child(n+5) {
    display: none;
  }
</style>
