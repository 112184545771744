<template>
  <div>
    <botton-bar/>
    <div class="content">
      <div class="bg-light position-relative rounded-top">
        <div class="container text-center">
          <router-link to="/perfil/dados" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
            Meus Dados
          </router-link>
          <router-link to="/perfil/confirmacoes" class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3">
            Presenças confirmadas
          </router-link>
          <button class="btn font-weight-bold btn-outline-primary btn-block py-3 mb-3" @click="logout">Apagar dados</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "../components/TopBar";
  import Lista from "../components/Lista";
  import CardInfo from "../components/CardInfo";
  import Icon from "../components/Icon";
  import BottonBar from "@/components/BottonBar";
  export default {
    name: 'Perfil',
    components: {BottonBar, Icon, CardInfo, Lista, TopBar},
    data() {
      return {
        token: localStorage.getItem('token'),
      }
    },

    methods: {
      logout() {
        this.$swal({
          text: "Deseja realmente apagar os dados do seu aplicativo?",
          buttons: ["Cancelar", "Sim!"],
          dangerMode: true
        }).then((res => {
          if (res) {
            this.$store.commit('logout');
            this.$router.push('/');
          }
        }));
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      }
    }
  }
</script>
